import React from "react";
import { Checkbox, FormControlLabel, Divider, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker } from "@material-ui/pickers";
import { useStyles } from "./style";
import PropTypes from "prop-types";
import moment from "moment";

const CreateOnePass = ({
  values,
  setFieldValue,
  options,
  createOnePassPerm,
  onPermissionChange,
  showNameFieldPerm,
  showRoomFieldPerm,
  formErrors,
  updateUserPermissions,
}) => {
  const classes = useStyles();

  const handleSelect = (event, newValue) => {
    setFieldValue("accessGroupId", newValue ? newValue.value : "");
  };

  const handleCreateOnePassToggle = (event) => {
    setFieldValue("accessGroupId", "");
    setFieldValue("onePassValidUntil", "");
    onPermissionChange(event);
    updateUserPermissions((prevPermissions) => ({
      ...prevPermissions,
      shownamefield: false,
      showroomfield: false
    }));
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={createOnePassPerm}
            onChange={handleCreateOnePassToggle}
            name="createonepass"
            value={values["createOnePass"]}
          />
        }
        label="Create One Pass"
      />
      <Divider />
      <div className={classes.subCheckbox}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="All Access" fullWidth />
          )}
          onChange={handleSelect}
          value={
            options.find((option) => option.value === values.accessGroupId) ||
            null
          }
          disabled={!createOnePassPerm}
        />
        <Divider />
        <DateTimePicker
          name="defaultOnePassExpirationTime"
          label="Default Expiration Time"
          value={
            values.onePassValidUntil
              ? moment
                  .utc(values.onePassValidUntil)
                  .local()
                  .format("MM/DD/YYYY h:mm A")
              : null
          }
          onChange={(val) => setFieldValue("onePassValidUntil", val || null)}
          error={formErrors && formErrors.onePassValidUntil ? true : false}
          helperText={formErrors && formErrors.onePassValidUntil}
          format="MM/DD/YYYY h:mm A"
          minDate={new Date()}
          disabled={!createOnePassPerm}
        />
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              checked={showRoomFieldPerm && createOnePassPerm}
              onChange={onPermissionChange}
              name="showroomfield"
              value={values["showRoomField"]}
              disabled={!createOnePassPerm}
            />
          }
          label="Show Room Field"
        />
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              checked={showNameFieldPerm && createOnePassPerm}
              onChange={onPermissionChange}
              name="shownamefield"
              value={values["showNameField"]}
              disabled={!createOnePassPerm}
            />
          }
          label="Show Name Field"
        />
      </div>
    </>
  );
};

CreateOnePass.propTypes = {
  values: PropTypes.shape({
    accessGroupId: PropTypes.string,
    onePassValidUntil: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.instanceOf(Date),
    ]),
    createOnePass: PropTypes.bool,
    showRoomField: PropTypes.bool,
    showNameField: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  createOnePassPerm: PropTypes.bool,
  onPermissionChange: PropTypes.func.isRequired,
  showNameFieldPerm: PropTypes.bool,
  showRoomFieldPerm: PropTypes.bool,
  formErrors: PropTypes.shape({
    onePassValidUntil: PropTypes.string,
  }),
  updateUserPermissions: PropTypes.func.isRequired,
};

CreateOnePass.defaultProps = {
  createOnePassPerm: false,
  showNameFieldPerm: false,
  showRoomFieldPerm: false,
  formErrors: {},
  options: [],
  values: {
    accessGroupId: "",
    onePassValidUntil: null,
    createOnePass: false,
    showRoomField: false,
    showNameField: false,
  },
};

export default CreateOnePass;
